<template>
  <a-row>
    <div class="background-text">
      <div class="text-item">
        <span>S</span>
      </div>
      <div class="text-item">
        <span>E</span>
      </div>
      <div class="text-item">
        <span>R</span>
      </div>
      <div class="text-item">
        <span>V</span>
      </div>
      <div class="text-item">
        <span>I</span>
      </div>
      <div class="text-item">
        <span>C</span>
      </div>
      <div class="text-item">
        <span>E</span>
      </div>
      <div class="text-item">
        <span>S</span>
      </div>
    </div>
    <a-col>
      <div class="textCenter">
        <h2 class="homeTitle">
          我们的服务
        </h2>
        <span class="title-span ">WE DO IT</span>
      </div>
      <div class="InBox">
        <div class="inUl">
          <div class="card-item">
            <span class="title-num">
              01
            </span>
            <h1 class="title">
              集运系统
            </h1>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">协同：PC/手机多端平台协同</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">管理系统：会员, 充值, 积分</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <div>
                <span class="text">营销：优惠券, 邀请裂变, 代</span>
                <p class="text lineHight">理模式</p>
              </div>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">数据分析：数据化统计报表</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">集成即时通讯：网页在线客服</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">定制：可支持二次开发</span>
            </div>
            <div class="bgImg">
              <img class="img" src="@/assets/smartcat/01.png" alt="">
            </div>
          </div>
          <div class="card-item">
            <span class="title-num">
              02
            </span>
            <h1 class="title">
              仓储服务
            </h1>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">全程可视化专业打包</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">国内快递无缝对接</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <div>
                <span class="text">入库质检购物放心</span>
              </div>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">180天仓储库位</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">真空压缩打包</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">24小时内发货</span>
            </div>
            <div class="bgImg">
              <img class="img" src="@/assets/smartcat/02.png" alt="">
            </div>
          </div>
          <div class="card-item">
            <span class="title-num">
              03
            </span>
            <h1 class="title">
              国际物流服务
            </h1>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">物流线路覆盖200+国家</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">提供空运, 海运, 铁路等</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <div>
                <span class="text">全品类：普货, 带电, 食品,</span>
                <p class="text lineHight">敏感线</p>
              </div>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">一站式仓到门派发</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">丢失理赔保险</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">专业售后全程跟踪</span>
            </div>
            <div class="bgImg">
              <img class="img" src="@/assets/smartcat/03.png" alt="">
            </div>
          </div>
          <div class="card-item">
            <span class="title-num">
              04
            </span>
            <h1 class="title">
              展业支持服务
            </h1>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">集运顾问1对1陪伴式成长</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">境内公司注册服务</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <div>
                <span class="text">代理记账服务</span>
              </div>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">集运商学院</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">客服服务</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">集运平台建站服务</span>
            </div>
            <div class="msg">
              <img class="img" src="@/assets/smartcat/ticks.png" alt="">
              <span class="text">代运营服务</span>
            </div>
            <div class="bgImg">
              <img class="img" src="@/assets/smartcat/04.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </a-col>
    <div class="quickCreate">
      <div class="container">
        <div class="container-left">
          <img class="phoneImg" src="@/assets/smartcat/Rectanglex.png" alt="">
          <img class="rowImg" src="@/assets/smartcat/row.png" alt="">
        </div>
        <div class="container-right">
          <div class="head">
            <span class="homeTitle">快速建立自己的集运站</span>
          </div>
          <div class="example-item">
            <div class="item-img">
              <img class="img" src="@/assets/smartcat/work.png" alt="">
            </div>
            <div class="text">
              <h1 class="h1">零成本创业</h1>
              <span class="span">0加盟费、0保证金、 无订单无费用，低风险轻松创业</span>
            </div>
          </div>
          <div class="example-item">
            <div class="item-img">
              <img class="img" src="@/assets/smartcat/create.png" alt="">
            </div>
            <div class="text">
              <h1 class="h1">陪伴式成长</h1>
              <span class="span">系统建站流程培训、仓储物流服务培训、促销推广培训等顾问团Nv1全程陪伴助力成长</span>
            </div>
          </div>
          <div class="example-item">
            <div class="item-img">
              <img class="img" src="@/assets/smartcat/service.png" alt="">
            </div>
            <div class="text">
              <h1 class="h1">全链路集运服务</h1>
              <span class="span">集运IT系统、仓储服务、国际物流服务、集运售后服务等全链路一站式解决方案</span>
            </div>
          </div>
          <div class="footerBtn">
            <button class="btn" @click="knowMore">了解更多</button>
          </div>
        </div>
      </div>
    </div>
    <div class="aboutUs">
      <div class="about-left">
        <div class="head">
          <span class="homeTitle">
            ABOUT US
          </span>
        </div>
        <div class="small-text">
          <span class="small">why SmartCat?</span>
        </div>
        <div class="container">
          <p class="item">上海嘿猫嗨购信息技术有限公司(SMARTCAT嘿猫嗨购全球集</p> <br>
          <p class="item">运建站平台) 是一站式全球集运解决方案领先者，致力于为数</p> <br>
          <p class="item">万位海外创业者，中小企业拓展国货出海提供商业机会。依托</p> <br>
          <p class="item">母公司YDH20年物流仓储服务体系,覆盖全球200+国家物流线</p> <br>
          <p class="item">路，领先的集运系统开发团队，为集运商家提供便捷的集运系</p> <br>
          <p class="item">统，全程可视化仓储服务，国际物流服务等一站式集运解决方</p> <br>
          <p class="item">案。</p>
        </div>
        <!-- <div class="choice">
          <span class="choice-our">为什么选择我们</span>
          <img class="img" src="@/assets/smartcat/greater.png" alt="">
        </div> -->
      </div>
      <div class="about-right">
        <img class="img" src="@/assets/smartcat/aboutUs.png" alt="">
      </div>
    </div>
    <div>
    </div>
    <div class="partner">
      <div class="background-text">
        <div class="text-item">
          <span>P</span>
        </div>
        <div class="text-item">
          <span>A</span>
        </div>
        <div class="text-item">
          <span>R</span>
        </div>
        <div class="text-item">
          <span>T</span>
        </div>
        <div class="text-item">
          <span>N</span>
        </div>
        <div class="text-item">
          <span>E</span>
        </div>
        <div class="text-item">
          <span>R</span>
        </div>
        <div class="text-item">
          <span>S</span>
        </div>
      </div>
      <div class="partner-textCenter">
        <h2 class="homeTitle">
          合作伙伴
        </h2>
        <div class="title-span">专业物流20年, 众多合作伙伴</div>
      </div>
      <div class="partner-work">
        <div class="partner-item">
          <div>
            <img class="expressImg" src="@/assets/smartcat/express1.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express2.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express3.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express4.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express5.png" alt="">
          </div>
        </div>
        <div class="partner-item">
          <div>
            <img class="expressImg" src="@/assets/smartcat/express6.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express7.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express8.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express9.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express10.png" alt="">
          </div>
        </div>
        <div class="partner-item">
          <div>
            <img class="expressImg" src="@/assets/smartcat/express11.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express12.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express13.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express14.png" alt="">
          </div>
          <div>
            <img class="expressImg" src="@/assets/smartcat/express15.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="contact-bg">
        <div class="contact-container">
          <div class="background-text">
            <div class="text-item">
              <span>C</span>
            </div>
            <div class="text-item">
              <span>O</span>
            </div>
            <div class="text-item">
              <span>N</span>
            </div>
            <div class="text-item">
              <span>T</span>
            </div>
            <div class="text-item">
              <span>A</span>
            </div>
            <div class="text-item">
              <span>C</span>
            </div>
            <div class="text-item">
              <span>T</span>
            </div>
            <div class="text-item">
              <span>U</span>
            </div>
            <div class="text-item">
              <span>S</span>
            </div>
          </div>
          <div class="contact-textCenter">
            <h2 class="homeTitle">
              联系我们
            </h2>
            <div class="title-span">致力于为数万位海外创业者,&nbsp;&nbsp; NV1顾问团陪伴式成长助力,&nbsp; &nbsp;让小白也可以轻松集运!</div>
          </div>
          <div class="contact-info">
            <div class="contact-item">
              <input class="input" v-model="form.name" placeholder="Name*">
            </div>
            <div class="contact-item">
              <input class="input" v-model="form.email" placeholder="Email*" />
            </div>
            <div class="contact-item">
              <input class="input" v-model="form.country" placeholder="Country*" />
            </div>
            <div class="contact-item">
              <input class="textarea" v-model="form.message" type="textarea" style="
            overflow:auto" placeholder="Message*" />
            </div>
          </div>
          <div class="contact-footer">
            <div class="container">
              <div class="contact-left">
                <div class="sendBtn">
                  <button class="btn" @click="contactOur">Send now</button>
                </div>
                <div class="invite">
                  <p class="spanText">诚邀您扫描我们的二维码，以便持续与您</p>
                  <p class="spanText">保持联系并提供更好的服务！</p>
                </div>
              </div>
              <div class="contact-right">
                <div class="contact-code">
                  <img class="code" src="@/assets/smartcat/weChat.jpg" alt="">
                  <img class="rowImg" src="@/assets/smartcat/row.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-row>
</template>

<script>
export default {
  name: 'homeComIntroduce',
  data () {
    return {
      form: {
        name: '',
        email: '',
        country: '',
        message: ''
      }
    }
  },

  methods: {
    contactOur () {
      if (!this.form.name) {
        return this.$message.error('请填写名字')
      } else if (!this.form.email) {
        return this.$message.error('请填写邮件')
      } else if (!this.form.country) {
        return this.$message.error('请填写国家')
      } else if (!this.form.message) {
        return this.$message.error('请填写信息')
      }
      let params = this.form
      this.$request.contact(params).then(res => {
        if (res.ret) {
          this.$message.success('提交成功')
          this.clear()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    clear () {
      this.form.name = '',
        this.form.email = '',
        this.form.country = '',
        this.form.message = ''
    },
    knowMore() {
      this.$emit("showDialog")
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.background-text {
  margin: 120px auto;
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 60px;
  position: relative;

  .text-item {
    height: 101px;
    font-size: 72px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 100.8px;
    color: #f5f5f5;
  }
}

.textCenter {
  position: absolute;
  left: 50%;
  top: -32%;
  transform: translate(-50%, -50%);
  text-align: center;

  .homeTitle {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32px;
    color: rgba(0, 0, 0, 1);
    // scroll-padding-top: 125px;
    background: linear-gradient(to top, #fdd000 4px, white 50%, white 51%, white 100%);

    .center {
      text-align: center;

    }
  }

}



.InBox {
  display: flex;
  justify-content: center;

  .inUl {
    display: flex;
    justify-content: center;
    align-items: center;

    .card-item {
      width: 285px;
      height: 380px;
      margin: 10px;
      border: 1px solid #eaeefb;
      border-radius: 8px;
      background-color: #ffffff;
      transition: background-color .3s;
      box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.1), 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
      position: relative;

      .title-num {
        height: 20px;
        font-size: 72px;
        font-weight: 700;
        margin-left: 20px;
        line-height: 20px;
        background: linear-gradient(to top, #fff, #fdd000);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      .title {
        font-size: 22px;
        font-weight: 500;
        margin-left: 20px;
        margin-top: -14px;
        color: #000000;
      }

      .msg {
        display: flex;
        margin-left: 20px;

        .img {
          width: 20px;
          height: 20px;
          margin-top: 10px;
        }

        .text {
          line-height: 40px;
          margin-left: 10px;
          font-size: 16px;

        }

        .lineHight {
          line-height: 0;
        }
      }

      .bgImg {

        .img {

          position: absolute;
          right: 0;
          bottom: 0;
          width: 189px;
          height: 99px;
        }
      }
    }


  }
}

.quickCreate {
  width: 100%;
  height: 800px;
  background-color: #f8f8f8;
  margin-top: 150px;

  .container {
    display: flex;
    width: 1200px;
    height: 800px;
    margin: auto;
    position: relative;

    .container-left {
      width: 600px;

      .phoneImg {
        margin-top: -90px;
        width: 420px;
        height: 820px;
        z-index: 999;
        position: absolute;
      }

      .rowImg {
        position: absolute;
        bottom: 80px;
        left: 0;
        width: 140px;
        height: 140px;
        z-index: 10;
      }
    }

    .container-right {
      width: 600px;

      .head {
        margin-top: 120px;

        .homeTitle {
          display: inline-block;
          height: 36px;
          font-size: 36px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 36px;
          color: rgba(0, 0, 0, 1);
          background: linear-gradient(to top, #fdd000 2px, #f8f8f8 40%, #f8f8f8 51%, #f8f8f8 100%);
        }
      }

      .example-item {
        display: flex;
        margin-top: 30px;
        margin-bottom: 40px;

        .item-img .img {
          width: 80px;
          height: 80px;
          margin-right: 30px;
          margin-top: 10px;
        }

        .text {
          .h1 {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 28px;
            color: rgba(0, 0, 0, 1);
          }

          .span {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22.4px;
            color: #7f7f7f;
          }
        }
      }

      .footerBtn {
        margin-top: 60px;
        margin-left: 110px;

        .btn {
          width: 160px;
          height: 44px;
          border-radius: 22px;
          border: none;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(0, 0, 0, 1);
          background-color: #ffd000;
          cursor: pointer;

        }

        .btn:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
}

.aboutUs {
  margin: auto;
  margin-top: 120px;
  width: 1200px;
  height: 450px;
  display: flex;
  background: #f8f8f8;
  border-radius: 10px;

  .about-left {
    width: 600px;

    .head {
      margin-top: 40px;
      margin-left: 60px;

      .homeTitle {
        display: inline-block;
        height: 23px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 20px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 2px, #f8f8f8 40%, #f8f8f8 51%, #f8f8f8 100%);

      }
    }

    .small-text {
      margin-top: 40px;
      margin-left: 60px;

      .small {
        width: 158px;
        height: 28px;
        opacity: 1;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .container {
      margin-top: 20px;
      margin-left: 60px;
      width: 500px;
      height: 192px;

      .item {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 8px;
        color: rgba(68, 68, 68, 1);

      }
    }

    .choice {
      margin-top: 20px;
      margin-left: 60px;

      .choice-our {
        display: inline-block;
        width: 124px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 16px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 2px, #f8f8f8 20%, #f8f8f8 51%, #f8f8f8 100%);
      }

      .img {
        width: 16px;
        height: 16px;
        margin-top: -2px;
        margin-left: -12px;
      }
    }

  }

  .about-right {
    width: 600px;

    .img {
      width: 600px;
      height: 450px;
    }
  }
}

.partner {
  position: relative;

  .background-text {
    margin: 120px auto;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 60px;


    .text-item {
      height: 101px;
      font-size: 72px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 100.8px;
      color: #f5f5f5;
    }
  }

  .partner-textCenter {
    position: absolute;
    left: 50%;
    top: 8%;
    transform: translate(-50%, -50%);
    text-align: center;

    .homeTitle {
      width: 146px;
      margin: auto;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 32px;
      color: rgba(0, 0, 0, 1);
      // scroll-padding-top: 125px;
      background: linear-gradient(to top, #fdd000 4px, white 50%, white 51%, white 100%);

    }

    .title-span {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
    }
  }

  .partner-work {
    width: 1200px;
    margin: auto;

    .partner-item {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      .expressImg {
        width: 200px;
        height: 100px;
      }
    }

  }
}

.contact {
  width: 100%;
  margin: auto;
  margin-top: 50px;
  background-color: #f8f8f8;
  padding-top: 80px;
  padding-bottom: 80px;

  .contact-bg {
    // width: 1920px;
    margin: auto;
    background: url(~@/assets/smartcat/map1.png.svg) no-repeat;
    background-size: 100% 100%;
  }

  .contact-container {
    width: 1200px;
    position: relative;
    // background: url(~@/assets/smartcat/texture.svg) no-repeat;
    margin: auto;

    .background-text {
      margin: auto;
      display: flex;
      justify-content: space-between;
      width: 1200px;

      .text-item {
        height: 101px;
        font-size: 72px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 100.8px;
        color: #e6e6e6;
      }
    }

    .contact-textCenter {
      position: absolute;
      left: 50%;
      top: 6%;
      transform: translate(-50%, -50%);
      text-align: center;

      .homeTitle {
        width: 146px;
        margin: auto;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 4px, white 50%, white 51%, white 100%);

      }

      .title-span {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }
    }

    .contact-info {
      width: 1200px;
      margin: auto;
      margin-top: 130px;

      .contact-item {
        width: 600px;
        height: 44px;
        line-height: 44px;
        margin: auto;
        margin-bottom: 40px;

        .input {
          width: 600px;
          border-width: 0px;
          height: 44px;
          border-radius: 22px;
          padding-left: 20px;
          background-color: #fff;
          // border: 1px solid#ffd000;
          outline: none;
        }


        .textarea {
          width: 600px;
          height: 140px;
          padding-left: 20px;
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #fff;
          padding-bottom: 90px;
          outline: none;
        }
      }

    }

    .contact-footer {
      width: 1200px;
      height: 300px;
      margin: auto;
      margin-top: 120px;

      .container {
        width: 600px;
        height: 200px;
        margin: auto;
        display: flex;
        position: relative;

        .contact-left {
          .sendBtn {
            margin-bottom: 80px;

            .btn {
              margin-top: 10px;
              width: 160px;
              height: 44px;
              border-radius: 22px;
              background: rgba(255, 208, 0, 1);
              border-color: #fdd000 !important;
              cursor: pointer;
              border-width: 0;
            }

            .btn:hover {
              border-color: #000 !important;
              color: #fff;
              background: #000;
            }
          }

          .invite {
            width: 370px;
            height: 56px;
            display: inline-block;


            .spanText {
              font-size: 20px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 10px;
              color: rgba(0, 0, 0, 1);
            }
          }

        }

        .contact-right {
          margin-top: 10px;

          .contact-code {

            .code {
              width: 200px;
              height: 200px;
              z-index: 999;
              right: 0px;

              position: absolute;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
            }

            .rowImg {
              position: absolute;
              bottom: -50px;
              right: -45px;
              width: 140px;
              height: 140px;
              z-index: 10;
            }
          }
        }
      }
    }
  }

}
</style>
