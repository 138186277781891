<template>
  <div class="home">
    <homeComAbout ref="child" @showDialog="showDialog" :src="bgImg"></homeComAbout>
    <homeComIntroduce @showDialog="showDialog"></homeComIntroduce>
  </div>
</template>

<script>
import homeComAbout from "./homeComAbout.vue"
import homeComIntroduce from "./homeComIntroduce.vue"
export default {
  name: 'homePage',
  components: {
    homeComAbout,
    homeComIntroduce
  },
  data () {
    return {
      bgImg:require('@/assets/smartcat/banner.png')
    }
  },
  methods: {
    showDialog() {
        this.$refs.child.showContact();
      }
  }
}
</script>

<style lang="scss">

.home {
  background: #fff;
}
</style>
